import TomSelect from 'tom-select/dist/js/tom-select.complete.js';

document.addEventListener('select2:loaded', function () {
  // Format currency on form inputs
  if ($('input.js-currency').val() != '') {
    $('input.js-currency').val(function () {
      return numeral($(this).val()).format('$0,0.00');
    });
  }

  $('input.js-currency').change(function () {
    $(this).val(function () {
      return numeral($(this).val()).format('$0,0.00');
    });
  });

  $('form').submit(function (event) {
    $('input.js-currency').val(function () {
      return numeral($(this).val()).value();
    });
  });

  // Select2 form selects
  $('.organization_select').each(function () {
    var params = '';
    if (this.dataset.metaOnly) params = '?meta_only=true';
    else if (this.dataset.localOnly) params = '?local_only=true';
    var placeholder = this.dataset.placeholder || 'Select Organization';

    $(this)
      .select2({
        theme: 'bootstrap-5',
        ajax: {
          url: '/organizations/search.json' + params,
          dataType: 'json',
        },
        minimumInputLength: 3,
        placeholder,
        allowClear: true,
        tags: this.dataset.allowCustom,
        createTag: (params) => ({
          id: params.term,
          text: params.term,
          custom: true,
        }),
      })
      .on('select2:select', function (e) {
        $('.organization_name_input').val(e.params.data.name);
        $('.organization_contact_name_input').val(e.params.data.contact_name);
        $('.organization_ein_input').val(e.params.data.ein);
        $('.organization_address_input').val(e.params.data.address);
        $('.organization_city_input').val(e.params.data.city);
        $('.organization_state_input').val(e.params.data.state);
        $('.organization_zip_input').val(e.params.data.zip);
        $('.impact_select').val(e.params.data.impact_id);
        $('#is_custom_organization').val(e.params.data.custom);
      });
  });

  $('.user_select').each(function () {
    var excluded = this.dataset.excluded;
    var communityId = this.dataset.communityId;
    var accountId = this.dataset.accountId;
    var extended = this.dataset.extended;

    $(this).select2({
      theme: 'bootstrap-5',
      ajax: {
        url: '/users/search.json',
        dataType: 'json',
        data: function (params) {
          return {
            q: params.term,
            excluded,
            community_id: communityId,
            account_id: accountId,
            extended: extended,
          };
        },
      },
      minimumInputLength: 3,
      placeholder: 'Select User',
    });
  });

  $('.project_select').each(function () {
    var accountId = this.dataset.accountId;

    $(this).select2({
      theme: 'bootstrap-5',
      ajax: {
        url: '/projects/search.json',
        dataType: 'json',
        data: function (params) {
          return {
            q: params.term,
            account_id: accountId,
          };
        },
      },
      minimumInputLength: 3,
      placeholder: 'Select Project',
    });
  });
  $('#taggable_tag_ids').select2({ theme: 'bootstrap-5' });
  $('#multiple_impact_ids').select2({ theme: 'bootstrap-5' });

  // Tom Select
  // TODO: Convert all select2 to Tom Select
  if (document.getElementById('question_ids')) {
    new TomSelect('#question_ids', {
      plugins: ['drag_drop', 'remove_button'],
    });
  }

  // Ranges
  function setBubble(range, bubble) {
    const val = range.val();
    const min = range.attr('min') || 0;
    const max = range.attr('max') || 0;
    const location = Number(((val - min) * 100) / (max - min));
    bubble.html(val);
    bubble.css('left', `calc(${location}% + (${13 - location * 0.25}px))`);
  }

  $('input[type="range"]').each(function () {
    const range = $(this);
    const bubble = range.siblings('.range-bubble');
    if (bubble.length == 0) return;

    range.on('input', () => setBubble(range, bubble));
    setBubble(range, bubble);
  });
});
