$(function () {
  // Only execute this logic on reporting screens
  if ($('.admin.reports').length == 0) return;

  $('.expand-stats').on('click', function() {
    var breakdown = $(this).parent().siblings();
    breakdown.addClass('d-flex');

    setTimeout(() => {
      window.onclick = function(e) {
        var target = $(e.target)
        if (!e.target.matches('.stat-card.inner') && target.parents('.stat-card.inner').length == 0) {
          hideBreakdown();
        }
      }
    }, 100);
    

    function hideBreakdown() {
      breakdown.removeClass('d-flex');
      window.onclick = undefined;
    }
    $('.collapse-stats', breakdown).on('click', hideBreakdown);
  });
});
