function uploadAttachment(attachment) {
  var csrfToken = $('meta[name="csrf-token"]').attr('content');
  var file = attachment.file;
  var form = new FormData();
  var endpoint = '/trix';
  form.append('Content-Type', file.type);
  form.append('attachment[file]', file);

  const xhr = new XMLHttpRequest();
  xhr.open('POST', endpoint, true);
  xhr.setRequestHeader('X-CSRF-Token', csrfToken);

  xhr.upload.onprogress = function (event) {
    var progress = (event.loaded / event.total) * 100;
    return attachment.setUploadProgress(progress);
  };

  xhr.onload = function () {
    if (this.status >= 200 && this.status < 300) {
      var data = JSON.parse(this.responseText);
      return attachment.setAttributes({
        url: data.url,
        href: data.url,
      });
    } else {
      return attachment.setAttributes({
        content: '<span class="fw-bold text-danger">Could not upload, unsupported file type</span>',
      });
    }
  };

  return xhr.send(form);
}

document.addEventListener('trix-attachment-add', function (event) {
  event.stopPropagation();
  var attachment = event.attachment;
  if (attachment.file) {
    return uploadAttachment(attachment);
  }
});

document.addEventListener('trix-before-initialize', () => {
  Trix.config.blockAttributes.heading1 = {
    tagName: 'h3',
    terminal: true,
    breakOnReturn: true,
    group: false,
  };
});
