import flatpickr from 'flatpickr';

function getDate(selectedDates) {
  if (selectedDates && selectedDates.length > 0) return selectedDates[0];
  return null;
}
function formatMobileDate(date) {
  if (date) return moment(date).format('YYYY-MM-DDThh:mm');
}

$(window).on('load', function () {
  // Convert each .datetimepicker into a flatpickr instance
  $('.datetimepicker').each(function (i) {
    var options = JSON.parse(this.getAttribute('data-date-options') || '{}');

    if (this.value) {
      options.defaultDate = this.value;
    } else if (options.defaultDate == 'now') {
      var now = new Date();
      now.setMinutes(0, 0, 0);
      options.defaultDate = now;
    }

    flatpickr(this, options);
  });

  // Allow flatpickr instances to be linked together for time range functionality
  var linkedPickers = $('.linked .flatpickr-input:not(.flatpickr-mobile)');
  linkedPickers.each(function (i) {
    // Only target the first of the datepickers in a set
    if (i % 2 == 1) return true;

    var startPicker = linkedPickers.get(i)._flatpickr;
    var endPicker = linkedPickers.get(i + 1)._flatpickr;
    var mobileEndPicker = linkedPickers.eq(i + 1).siblings('.flatpickr-mobile');

    // Set initial minimum date for linked picker
    var minDate = getDate(startPicker.selectedDates);
    endPicker.set('minDate', minDate);
    if (mobileEndPicker) mobileEndPicker.attr('min', formatMobileDate(minDate));

    // Monitor for changes and set minimum date on change
    startPicker.config.onChange.push(function (selectedDates) {
      var date = getDate(selectedDates);
      if (date) {
        const linkedDate = getDate(endPicker.selectedDates);
        endPicker.set('minDate', date);
        if (mobileEndPicker) mobileEndPicker.attr('min', formatMobileDate(date));

        if (linkedDate < date) endPicker.setDate(date);
      }
    });
  });
});
