import AwsS3 from '@uppy/aws-s3';
import Uppy from '@uppy/core';
import Dashboard from '@uppy/dashboard';
import StatusBar from '@uppy/status-bar';
import { nanoid } from 'nanoid';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/status-bar/dist/style.css';

/**
 * Initialize Uppy for uploading to a gallery
 */
function setupUppyGallery() {
  let uppy = new Uppy({
    autoProceed: false,
    allowMultipleUploads: true,
  })
    .use(AwsS3, { companionUrl: '/' })
    .use(Dashboard, {
      inline: true,
      trigger: '.uppy-modal-trigger',
      target: '#drag-and-drop-area',
      theme: 'dark',
      width: 750,
      height: 250,
      thumbnailWidth: 100,
      locale: {
        strings: {
          dropPasteFiles: 'Drag images to upload or %{browseFiles}',
          browseFiles: 'Select Photos',
          xFilesSelected: {
            0: '%{smart_count} photo selected',
            1: '%{smart_count} photos selected',
          },
          uploadingXFiles: {
            0: 'Uploading %{smart_count} photo',
            1: 'Uploading %{smart_count} photos',
          },
          processingXFiles: {
            0: 'Processing %{smart_count} photo',
            1: 'Processing %{smart_count} photos',
          },
        },
      },
    })
    .on('upload-success', (file, response) => {
      const parentClass = document.getElementById('gallery_parent_class').value;
      const hiddenField = document.createElement('input');

      hiddenField.type = 'hidden';
      hiddenField.name = `${parentClass}[photos_attributes][${nanoid()}][image]`;
      hiddenField.value = uploadedFileData(file, response);

      // hard coded form selector
      let element = $('form.edit_gallery')[0];
      element.appendChild(hiddenField);
    })
    .on('complete', () => {
      $('form.edit_gallery')[0].submit();
    });
}

/**
 * Initialize Uppy on all file inputs on page
 */
function setupUppyInputs() {
  $('.file-input-group').each(function (index, group) {
    const fileInput = group.querySelector('input[type=file]');
    const cachedInput = group.querySelector('input[type=hidden].cached');
    const label = group.querySelector('label');
    const removeFile = $('.remove-file', group);
    var addedFile = null;

    // Initialize Uppy
    const uppyInput = new Uppy({
      autoProceed: true,
      // allowMultipleUploadBatches: false,
      restrictions: {
        maxFileSize: 10 * 1024 * 1024,
        maxNumberOfFiles: 1,
        allowedFileTypes: fileInput.accept.split(','),
      },
    })
      .use(AwsS3, { companionUrl: '/' })
      .use(StatusBar, {
        target: group.querySelector('.file-input-progress'),
        hideUploadButton: true,
        hideAfterFinish: false,
      })
      .on('upload-success', function (file, response) {
        // Attach upload callback
        cachedInput.value = uploadedFileData(file, response);

        label.querySelector('span').innerHTML = file.name;
        label.classList.remove('has-error');

        var errors = group.querySelector('label + .invalid-feedback');
        if (errors) errors.remove();

        removeFile.show();
      })
      .on('restriction-failed', function (file, error) {
        // Display restriction failures
        alert(error);
      });

    // Attach upload button
    fileInput.addEventListener('change', function (event) {
      $(event.target.files).each(function (index, file) {
        try {
          addedFile = uppyInput.addFile({
            source: 'file input',
            name: file.name,
            type: file.type,
            data: file,
          });
        } catch (err) {
          if (err.isRestriction) {
            console.log('Restriction error:', err);
          } else {
            console.error(err);
          }
        }
      });
    });

    // Display cached file details on form reload
    function handleCachedInput() {
      if (cachedInput.value == '') return;
      var cachedFileData = JSON.parse(cachedInput.value);
      label.querySelector('span').innerHTML = cachedFileData.metadata.filename;
      removeFile.show();
    }
    handleCachedInput();
    $(cachedInput).on('change', handleCachedInput);

    // Remove file handler
    removeFile.on('click', function () {
      uppyInput.removeFile(addedFile);
      label.querySelector('span').innerHTML = 'Choose a file...';
      cachedInput.value = null;
      removeFile.hide();
    });
  });
}

/**
 * Convert uploaded file into a Rails-specific format
 * @param {*} file
 * @param {*} response
 * @returns
 */
function uploadedFileData(file, response) {
  const id = file.meta.key.match(/^cache\/(.+)/)[1]; // object key without prefix
  const fileData = {
    id,
    storage: 'cache',
    metadata: {
      size: file.size,
      filename: file.name,
      mime_type: file.type,
    },
  };

  return JSON.stringify(fileData);
}

/**
 * Determine which Uppy uploaders to initialize
 */
$(function () {
  if ($('#uppyModal').length > 0) setupUppyGallery();
  if ($('.file-input-group.async').length > 0) setupUppyInputs();

  // Post photo galleries
  $('.photo-wrap').each(function () {
    $(this).magnificPopup({
      delegate: 'a',
      type: 'image',
      gallery: { enabled: true },
    });
  });

  // Photo gallery component
  const gallery = $('.popup-gallery');
  if (gallery.length > 0) {
    gallery.magnificPopup({
      delegate: 'a.img-link',
      type: 'image',
      tLoading: 'Loading image #%curr%...',
      gallery: {
        enabled: true,
        navigateByImgClick: true,
        preload: [0, 1], // Will preload 0 - before current, and 1 after the current image
      },
    });
  }
});
