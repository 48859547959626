$(function () {
  // Only execute this logic on DE&I forms
  if ($('.dei_form').length == 0) return;

  /**
   * "Other" option logic
   */
  function toggleOtherOptionVisibility(checkbox) {
    var otherInput = $(checkbox).closest('.checkbox-list-wrapper').children('.other-option-wrapper');
    if (checkbox.checked) {
      otherInput.show();
    } else {
      otherInput.hide();
      otherInput.find('input[type="text"]').val('');
    }
  }
  const otherOptions = $('input[data-other="true"]');
  otherOptions.each(function (i, el) {
    el.name = 'other-meta[]';
    toggleOtherOptionVisibility(el);
  });
  otherOptions.on('change', function () {
    toggleOtherOptionVisibility(this);
  });

  /**
   * "N/A" option logic
   */
  $('input[data-na="true"]').on('change', function () {
    if (this.checked) {
      $(this)
        .closest('.checkbox-list-wrapper')
        .find('input[type="checkbox"]')
        .not('input[data-na="true"]')
        .prop('checked', false)
        .trigger('change');
    }
  });
  $('input[type="checkbox"]')
    .not('input[data-na="true"]')
    .on('change', function () {
      if (this.checked) {
        $(this)
          .closest('.checkbox-list-wrapper')
          .find('input[data-na="true"]')
          .prop('checked', false)
          .trigger('change');
      }
    });

  /**
   * "Zip" option logic
   */
  $('input[data-zip="true"]').on('change', function () {
    $('input[data-hidden-zip="true"]').val(this.value);
  });
});
