import * as ActiveStorage from '@rails/activestorage';
import '@rails/actiontext';
import Trix from 'trix';
import { Tab, Tooltip, Modal } from 'bootstrap';

import $ from 'jquery';
import 'magnific-popup';
import URI from 'urijs';
import moment from 'moment/moment';
import numeral from 'numeral';

import 'highcharts';
import 'highcharts/modules/drilldown';
import 'add-to-calendar-button';

import '~/javascript/datepickers';
import '~/javascript/dei';
import '~/javascript/forms';
import '~/javascript/google_location';
import '~/javascript/stats';
import '~/javascript/trix';
import '~/javascript/uppy';

window.jQuery = window.$ = $;
window.URI = URI;
window.moment = moment;
window.numeral = numeral;
window.Trix = Trix;
window.BsTooltip = Tooltip;
window.BsModal = Modal;
window.BsTab = Tab;
import('bootstrap-table').then(() => {
  import('bootstrap-table/dist/extensions/mobile/bootstrap-table-mobile').then(() => {
    document.dispatchEvent(new Event('bootstrap-table:loaded'));
  });
});
import('select2').then((S) => {
  S.default(jQuery.fn);
  document.dispatchEvent(new Event('select2:loaded'));
});

$(() => {
  ActiveStorage.start();

  // Google maps
  if (window?.__env__?.GOOGLE_API_KEY) {
    const googleMapsScript = document.createElement('script');
    googleMapsScript.src = `https://maps.googleapis.com/maps/api/js?key=${window.__env__.GOOGLE_API_KEY}&libraries=places&callback=initGoogleMaps`;
    googleMapsScript.defer = true;
    googleMapsScript.async = true;
    document.head.appendChild(googleMapsScript);
  }

  // Rails UJS
  const ujsScript = document.createElement('script');
  ujsScript.src = 'https://cdn.jsdelivr.net/npm/@rails/ujs@7.1.2/app/assets/javascripts/rails-ujs.min.js';
  document.head.appendChild(ujsScript);

  // Initialize tooltips
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
  tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new Tooltip(tooltipTriggerEl);
  });
});
