window.initGoogleMaps = function () {
  function initAutocomplete(elementId, options, onSuccess, onFailure) {
    const input = document.getElementById(elementId);
    if (!input) return;

    var currentSearch = '';
    const autocomplete = new google.maps.places.Autocomplete(input, options);
    google.maps.event.addListener(autocomplete, 'place_changed', function () {
      const place = autocomplete.getPlace();

      if (place.geometry) {
        currentSearch = place.formatted_address;
        onSuccess(place);
      } else {
        currentSearch = null;
        input.value = null;
        onFailure(place);
      }
    });
    input.addEventListener('blur', () => {
      input.value = currentSearch;
    });
  }

  function extractGeneralLocation(place) {
    const components = place.address_components;
    if (!components) return null;

    const city = components.find((c) => c.types.includes('locality'))?.long_name;
    const state = components.find((c) => c.types.includes('administrative_area_level_1'))?.short_name;
    const zip = components.find((c) => c.types.includes('postal_code'))?.long_name;
    let location = [city, state].filter((x) => x).join(', ');
    if (zip) location += ` ${zip}`;
    return location;
  }

  // "My Location" search box in header
  initAutocomplete(
    'my-location-search',
    {
      types: ['(regions)'],
      componentRestrictions: {
        country: 'us',
      },
    },
    (place) => {
      const payload = {
        user: {
          location: extractGeneralLocation(place),
          latitude: place?.geometry?.location?.lat(),
          longitude: place?.geometry?.location?.lng(),
        },
      };
      $.ajax({
        url: '/profile',
        type: 'PATCH',
        data: payload,
        headers: {
          Accept: 'application/json',
        },
        success: () => window.location.reload(),
      });
    },
    () => {},
  );

  // "Location" search box in explore filters
  const latitudeInput = $('.location-radius [name="lat"]');
  const longitudeInput = $('.location-radius [name="lng"]');
  initAutocomplete(
    'location-search',
    {},
    (place) => {
      latitudeInput.val(place?.geometry?.location?.lat());
      longitudeInput.val(place?.geometry?.location?.lng());
    },
    () => {
      latitudeInput.val(null);
      longitudeInput.val(null);
    },
  );
};
